import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { Link } from "react-router-dom";
import { FindInPage, Add } from "@material-ui/icons";
import Cookies from "universal-cookie";
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function CutoffPerEmp(props) {
  const cookies = new Cookies();
  const permissions = cookies.get("permissions")["timesheet"];
  const [data, setData] = useState([]);
  const [coDetails, setCoDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  if (coDetails === undefined || coDetails.length == 0) {
    axios.get("api/cutoff/" + props.match.params.id).then((res) => {
      setCoDetails(res.data);
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/cutoff_peremp/" + props.match.params.id;
      const res = await axios.get(apiLink);

      setData(res.data);
      setFilteredData(res.data);
    };
    fetchData();
  }, []);

  const handleRecordPerPage = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleBack = () => {
    props.history.goBack();
  };
  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["first_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            data["last_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
    setCurrentPage(1);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / recordsPerPage); i++) {
    pages.push(i);
  }
  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row mb-10">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <h3>{coDetails.company_name
                  ? coDetails.company_name +
                    " " +
                    coDetails.f_co_from_date +
                    " - " +
                    coDetails.f_co_to_date +
                    " Timesheet"
                  : ""}</h3>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="btn-group float-right">
                    <button
                      className="btn btn-sm btn-danger float-right "
                      onClick={handleBack}
                    >
                      Back to Previous Page
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-20">
                <div class="col-sm-12">
                  <div class="table-wrap">
                    <div class="row mb-10">
                      <div class="col-md-6">
                        <label>
                          <select
                            class="custom-select custom-select-sm form-control form-control-sm"
                            onChange={handleRecordPerPage}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>{" "}
                        items
                      </div>
                      <div class="col-md-6">
                        <input
                          type="search"
                          class="form-control form-control-sm col-md-6 col-sm-6 float-right"
                          placeholder="Search"
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div class="table-responsive mb-10">
                      <table class="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center">
                            <th>Employee #</th>
                            <th>Name</th>
                            <th>Position</th>
                            <th>Department</th>
                            {/* <th>Cutoff Period</th> */}
                            <th>Attendance Count</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length > 0 ? (
                            currentData.map((cutoff) => (
                              <tr key={cutoff.id}>
                                <td>{cutoff.emp_no}</td>
                                <td>
                                  {cutoff.last_name + ", " + cutoff.first_name}
                                </td>
                                <td>{cutoff.position}</td>
                                <td>{cutoff.department}</td>
                                {/* <td>
                              {cutoff.f_co_from_date +
                                " - " +
                                cutoff.f_co_to_date}
                            </td> */}
                                <td>{cutoff.attendance_count}</td>
                                <td>
                                  <div className="btn-group">
                                    <Link
                                      to={{
                                        pathname: `/cutoff/peremp/view/${cutoff.cutoff_id}/${cutoff.employee_id}`,
                                        query: `/cutoff`,
                                      }}
                                      className="btn btn-sm btn-outline-primary"
                                      title={"View Employee Cutoff Details"}
                                    >
                                      <FindInPage />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="7" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <ul class="pagination float-right">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Timesheets
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
