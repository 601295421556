import React, { useState, useEffect } from "react";
import axios from "axios";
import { dateFormatter } from "../../../components/DtFormatter";
import AddED from "./AddED";
import EditED from "./EditED";
import DeleteED from "./DeleteED";
import multiColumnSort from "multi-column-sort";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const pay_period = ["Weekly", "15th & 30th", "15th", "30th"];
const ytd_field = [
  "Basic",
  "Deminimis",
  "O.Earning Exempt",
  "O.Earning",
  "13 Month Pay",
  "SSS Premium",
  "SSS Provident",
  "PhilHealth",
  "PAGIBIG",
  "O.Deductions",
  "WH Tax",
  "Non Taxed Earnings",
];

export default function EarningDeduction() {
  const permissions = cookies.get("permissions")["config"];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [typeSort, setTypeSort] = useState("DESC");
  const [nameSort, setNameSort] = useState("DESC");
  const [codeSort, setCodeSort] = useState("ASC");
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/earning_deduction";
      const res = await axios.get(apiLink);
      setData(res.data);
      const filterer = searcher(res.data, search);
      setFilteredData(filterer);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["code"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["name"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["type"].toLowerCase().includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };

  const handleSort = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    if (columnChanged == "code") {
      sorter = [
        ["active", "DESC"],
        ["code", codeSort],
        ["name", "ASC"],
        ["type", "ASC"],
      ];

      setCodeSort(setSort);
    } else if (columnChanged == "name") {
      sorter = [
        ["active", "DESC"],
        ["name", nameSort],
        ["code", "ASC"],
        ["type", "ASC"],
      ];

      setNameSort(setSort);
    } else if (columnChanged == "type") {
      sorter = [
        ["active", "DESC"],
        ["type", typeSort],
        ["code", "ASC"],
        ["name", "ASC"],
      ];
      setTypeSort(setSort);
    }

    const sorted = multiColumnSort(filteredData, sorter, getColumnValue);

    setFilteredData(sorted);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {permissions > 1 ? <AddED parentCallback={handleAddCallback} /> : ""}
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12"></div>
        <div className="col-lg-6 col-md-6 col-sm-12 pt-10">
          <input
            type="search"
            className="form-control form-control-sm col-md-6 col-sm-12 float-right"
            placeholder="Search..."
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-wrap">
            <div className="table-responsive mb-10">
              <table className="table table-sm table-bordered table-striped mb-0">
                <thead className="thead-secondary">
                  <tr className="text-center">
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("code", codeSort)}
                    >
                      Code &nbsp;
                      <i
                        className={
                          codeSort == "ASC"
                            ? "fa fa-arrow-down"
                            : "fa fa-arrow-up"
                        }
                      ></i>
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("name", nameSort)}
                    >
                      Name &nbsp;
                      <i
                        className={
                          nameSort == "ASC"
                            ? "fa fa-arrow-down"
                            : "fa fa-arrow-up"
                        }
                      ></i>
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("type", typeSort)}
                    >
                      Type &nbsp;
                      <i
                        className={
                          typeSort == "ASC"
                            ? "fa fa-arrow-down"
                            : "fa fa-arrow-up"
                        }
                      ></i>
                    </th>
                    <th>Taxable</th>
                    <th>Tax Exempt Ceiling</th>
                    <th>YTD Field</th>
                    <th>Sign</th>
                    <th>Status</th>
                    <th>Pre-Defined Settings</th>
                    <th>Last Update / Updated By</th>
                    <th style={{ width: "110px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((ed) => (
                      <tr key={ed.id} className="text-nowrap">
                        <td className="text-center">
                          
                            {ed.is_preset == 0 ? (
                              permissions > 2 ? (
                                <button className="btn btn-default text-success">
                                <EditED
                                  parentCallback={handleAddCallback}
                                  edData={ed}
                                  buttonType={0}
                                />
                                </button>
                              ) : (
                                ed.code
                              )
                            ) : (
                              ed.code
                            )}
                          
                        </td>
                        <td>{ed.name}</td>
                        <td className="text-center">
                          {ed.is_add == 1 ? (
                            <strong className="text-success">Earning</strong>
                          ) : (
                            ""
                          )}
                          {ed.is_statutory == 1 ? (
                            <strong className="text-warning">Statutory</strong>
                          ) : (
                            ""
                          )}
                          {ed.is_deduction == 1 ? (
                            <strong className="text-danger">Deduction</strong>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="text-center">
                          {ed.is_taxable == 0 ? "NO" : "YES"}
                        </td>
                        <td className="text-center">
                          {ed.tax_exempt == 0 ? "NO" : "YES"}
                        </td>
                        <td className="text-center">
                          {ytd_field[ed.ytd_field]}
                        </td>
                        <td className="text-center">{ed.sign_name}</td>
                        <td className="text-center">
                          <strong
                            className={
                              ed.active == 0 ? "text-danger" : "text-success"
                            }
                          >
                            {ed.active == 0 ? "INACTIVE" : "ACTIVE"}
                          </strong>
                        </td>
                        <td className="text-center">{ed.preset}</td>
                        <td>
                          {dateFormatter(
                            ed.updated_at,
                            "MMM dd, yyyy hh:mm aa"
                          )}
                          <br />
                          {ed.updated_by}
                        </td>
                        <td>
                          {ed.is_preset == 0 ? (
                            <div className="btn-group">
                              {permissions > 2 ? (
                                <EditED
                                  parentCallback={handleAddCallback}
                                  edData={ed}
                                  buttonType={1}
                                />
                              ) : (
                                ""
                              )}
                              {permissions > 3 ? (
                                 ed.id == 81 ? ("") : (
                                  <DeleteED
                                  parentCallback={handleAddCallback}
                                  edData={ed}
                                />
                                 )
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No Data to show
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
