import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { timeFormatter } from "../../components/DtFormatter";
import { Link } from "react-router-dom";
import axios from "axios";
import { FindInPage, Add } from "@material-ui/icons";
import AddCompany from "./AddCompany";
import DeleteCompany from "./DeleteCompany";
import Cookies from "universal-cookie";
const pay_period = [
  "Weekly",
  "15th & 30th (Semi-Monthly)",
  "15th (Monthly)",
  "30th (Monthly)",
];
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function Company(props) {
  const permissions = cookies.get("permissions")["master_data"];
  const [data, setData] = useState([]);
  const [schemaDD, setSchemaDD] = useState([]);
  const [bschemaDD, setBschemaDD] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [search, setSearch] = useState(
    props.location.search
      ? decodeURI(props.location.search.split("=").pop())
      : ""
  );

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/get_company/" + cookies.get("uid");
      const res = await axios.get(apiLink);
      setData(res.data);
      const filterer = searcher(res.data, search);
      setFilteredData(filterer);
    };

    const fetchDD = async () => {
      const getSchema = await axios.get("api/schemaDD");
      setSchemaDD(getSchema.data);
      const getBschema = await axios.get("api/basic_schemaDD2");
      setBschemaDD(getBschema.data);
    };

    if (!fileLoaded) {
      fetchDD();
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["name"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["code"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["schema_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div className="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row mb-10">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3>Company Information</h3>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="btn-group float-right">
                    {permissions > 1 ? (
                      <AddCompany
                        schemaDD={schemaDD}
                        bschemaDD={bschemaDD}
                        parentCallback={handleAddCallback}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="table-wrap">
                    <div className="row mb-10">
                      <div className="col-md-6">
                        <label>
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={handleRecordPerPage}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>{" "}
                        items
                      </div>
                      <div className="col-md-6">
                        <input
                          type="search"
                          className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                          placeholder="Search"
                          defaultValue={
                            props.location.search
                              ? decodeURI(
                                  props.location.search.split("=").pop()
                                )
                              : ""
                          }
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div className="table-responsive mb-10">
                      <table className="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center">
                            <th>Code</th>
                            <th>Logo</th>
                            <th>Name</th>
                            <th>Night Differential</th>
                            <th>Minimum OT(Hour)</th>
                            <th>Payroll Cycle</th>
                            <th>Basic Schema</th>
                            <th>
                              13th month Special Pay
                              <br />
                              w/o withholding tax
                            </th>
                            <th>
                              Fixed Mandatory Contribution
                              <br />
                              based on Monthly Basic
                            </th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length > 0 ? (
                            currentData.map((company) => (
                              <tr key={company.id} className="text-nowrap">
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/company/view/${company.id}`,
                                      query: `/company`,
                                      search: "?search=" + search,
                                    }}
                                    className="text-info"
                                    title={"View " + company.name + " Details"}
                                  >
                                    {company.code}
                                  </Link>
                                </td>
                                <td>
                                  <div className="avatar">
                                    {company.logo_file_name ? (
                                      <img
                                        src={
                                          process.env.REACT_APP_API_LINK +
                                          "companyLogos/" +
                                          company.logo_file_name
                                        }
                                        alt="user"
                                        className="avatar-img rounded-circle"
                                      />
                                    ) : (
                                      <img
                                        src="dist/img/avatar12.jpg"
                                        alt="user"
                                        className="avatar-img rounded-circle"
                                      />
                                    )}
                                  </div>
                                </td>
                                <td>{company.name}</td>
                                <td>
                                  {timeFormatter(company.night_diff_in) +
                                    " - " +
                                    timeFormatter(company.night_diff_out)}
                                </td>
                                <td className="text-right">
                                  {company.min_overtime}
                                </td>
                                <td>{pay_period[company.pay_period]}</td>
                                <td>{company.bschema_name}</td>
                                <td className="text-center">
                                  {company.t_month_tax_f}
                                </td>
                                <td className="text-center">
                                  {company.mandatory_fixed_f}
                                </td>
                                <td className="text-center">
                                  <strong
                                    className={
                                      company.status == "ACTIVE"
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {company.status}
                                  </strong>
                                </td>
                                <td>
                                  <div className="btn-group">
                                    <Link
                                      to={{
                                        pathname: `/company/view/${company.id}`,
                                        query: `/company`,
                                        search: "?search=" + search,
                                      }}
                                      className="btn btn-sm btn-outline-primary"
                                      title={
                                        "View " + company.name + " Details"
                                      }
                                    >
                                      <FindInPage />
                                    </Link>
                                    {permissions > 3 ? (
                                      <DeleteCompany
                                        name={company.name}
                                        id={company.id}
                                        status={company.status}
                                        parentCallback={handleAddCallback}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="8" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="drew-pagination">
                    {filteredData.length > 0
                      ? "Showing " +
                        (startIndex + 1) +
                        " to " +
                        (endIndex > filteredData.length
                          ? filteredData.length
                          : endIndex) +
                        " of " +
                        filteredData.length
                      : ""}
                    <ul className="pagination ml-2">
                      {pages.map((number) => (
                        <li
                          key={number}
                          id={number}
                          className={
                            currentPage == number
                              ? "paginate_button page-item active"
                              : "paginate_button page-item"
                          }
                          onClick={() => setCurrentPage(number)}
                        >
                          <a className="page-link">{number}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Master Data
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
