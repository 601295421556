import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { Link } from "react-router-dom";
import { FindInPage } from "@material-ui/icons";
import { dateFormatter } from "../../components/DtFormatter";
import Cookies from "universal-cookie";
import AddTransactionDed from "./AddTransactionDed";
import CloseTransaction from "./CloseTransaction";
import EditTransaction from "./EditTransaction";
import multiColumnSort from "multi-column-sort";
import ExportTransactionDed from "./TransactionDedExport";
import HoldTransaction from "./HoldTransaction";
import ContinueTransaction from "./ContinueTransaction";
import StopTransaction from "./StopTransaction";
const cookies = new Cookies();
const textColor = ["success", "warning", "success", "danger", "info", "danger"];
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function TransactionDed(props) {
  const permissions = cookies.get("permissions")["transaction"];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [currentPaySched, setCurrentPaySched] = useState([]);
  const [edDD, setEdDD] = useState([]);
  const [empDD, setEmpDD] = useState([]);
  const [paySchedDD, setPaySchedDD] = useState([]);
  const [nameSort, setNameSort] = useState("DESC");
  const [empNoSort, setEmpNoSort] = useState("DESC");
  const [edSort, setEdSort] = useState("DESC");
  const [payPeriodSort, setPayPeriodSort] = useState("DESC");
  const [statusSort, setStatusSort] = useState("ASC");
  const [updatedDateSort, setUpdatedDateSort] = useState("DESC");
  const [search, setSearch] = useState(
    props.location.search
      ? decodeURI(props.location.search.split("=").pop())
      : ""
  );

  useEffect(() => {
    const fetchData = async () => {
      let company_id = 0;
      if (cookies.get("transCompany")) company_id = cookies.get("transCompany");

      if (company_id == 0) {
        cookies.set("load", "1");
        alert("Please Select Module Company");
        window.location.replace("/#/app");
      } else {
        let apiLink = "api/transaction_ded/" + company_id;
        const res = await axios.get(apiLink);
        setData(res.data.transactions);
        setEdDD(res.data.edDD);
        setEmpDD(res.data.empDD);
        setPaySchedDD(res.data.paySchedDD);
        setCurrentPaySched(res.data.currentPaySched);

        const filterer = searcher(res.data.transactions, search);
        setFilteredData(filterer);
      }
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const optionData = empDD.map((data) => {
    return {
      value: data.id,
      label: data.last_name + ", " + data.first_name,
    };
  });

  const fromData = paySchedDD.map((data) => {
    return {
      value: data.id,
      label:
        data.code +
        " (" +
        dateFormatter(data.start_date) +
        " - " +
        dateFormatter(data.end_date) +
        ")",
    };
  });
  const toData = paySchedDD.map((data) => {
    return {
      value: data.id,
      label:
        data.code +
        " (" +
        dateFormatter(data.end_date) +
        " - " +
        dateFormatter(data.end_date) +
        ")",
    };
  });

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["first_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["last_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            (data["last_name"] + ", " + data["first_name"])
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["emp_no"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["ed_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["pay_cycle_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["ps_from_code"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["ps_to_code"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            (data["ps_from_code"] + " to " + data["ps_to_code"])
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["updated_at_f"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["status_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };

  const handleSort = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    if (columnChanged == "name") {
      sorter = [
        ["last_name", nameSort],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["ed_name", "ASC"],
        ["pay_cycle_name", "ASC"],
        ["status", "ASC"],
        ["updated_at", "ASC"],
      ];

      setNameSort(setSort);
    } else if (columnChanged == "empNo") {
      sorter = [
        ["emp_no", empNoSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["ed_name", "ASC"],
        ["pay_cycle_name", "ASC"],
        ["status", "ASC"],
        ["updated_at", "ASC"],
      ];

      setEmpNoSort(setSort);
    } else if (columnChanged == "status") {
      sorter = [
        ["status", statusSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["ed_name", "ASC"],
        ["pay_cycle_name", "ASC"],
        ["updated_at", "ASC"],
      ];

      setStatusSort(setSort);
    } else if (columnChanged == "ed") {
      sorter = [
        ["ed_name", edSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["pay_cycle_name", "ASC"],
        ["status", "ASC"],
        ["updated_at", "ASC"],
      ];

      setEdSort(setSort);
    } else if (columnChanged == "payPeriod") {
      sorter = [
        ["pay_cycle_name", payPeriodSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["ed_name", "ASC"],
        ["status", "ASC"],
        ["updated_at", "ASC"],
      ];

      setPayPeriodSort(setSort);
    } else if (columnChanged == "updatedDate") {
      sorter = [
        ["updated_at", updatedDateSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["ed_name", "ASC"],
        ["pay_cycle_name", "ASC"],
        ["status", "ASC"],
      ];

      setUpdatedDateSort(setSort);
    }

    const sorted = multiColumnSort(filteredData, sorter, getColumnValue);

    setFilteredData(sorted);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row mb-10">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3>Deduction Transactions</h3>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  {cookies.get("transCompany") == "" ? (
                    ""
                  ) : permissions > 1 ? (
                    <>
                      <ExportTransactionDed
                        company={cookies.get("transCompany")}
                      />
                      <AddTransactionDed
                        edDD={edDD}
                        empDD={optionData}
                        paySchedFrom={fromData}
                        paySchedTo={toData}
                        parentCallback={handleAddCallback}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mt-10">
                  <div class="table-wrap">
                    <div class="row mb-10">
                      <div class="col-md-6">
                        <label>
                          <select
                            class="custom-select custom-select-sm form-control form-control-sm"
                            onChange={handleRecordPerPage}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>{" "}
                        items
                      </div>
                      <div class="col-md-6">
                        <input
                          type="search"
                          class="form-control form-control-sm col-md-6 col-sm-6 float-right"
                          placeholder="Search"
                          defaultValue={
                            props.location.search
                              ? decodeURI(
                                  props.location.search.split("=").pop()
                                )
                              : ""
                          }
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div class="table-responsive mb-10">
                      <table class="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center text-nowrap">
                            <th
                              onClick={() => handleSort("empNo", empNoSort)}
                              style={{ cursor: "pointer" }}
                            >
                              Employee # &nbsp;
                              <i
                                className={
                                  empNoSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th
                              onClick={() => handleSort("name", nameSort)}
                              style={{ cursor: "pointer" }}
                            >
                              Employee Name &nbsp;
                              <i
                                className={
                                  nameSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th
                              onClick={() => handleSort("ed", edSort)}
                              style={{ cursor: "pointer" }}
                            >
                              Deduction &nbsp;
                              <i
                                className={
                                  edSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>

                            <th
                              onClick={() =>
                                handleSort("payPeriod", payPeriodSort)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              Pay Period Release &nbsp;
                              <i
                                className={
                                  payPeriodSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th>Total Amount</th>
                            <th>
                              Per Payment
                              <br />
                              Period
                              <br />
                              Amount
                            </th>
                            <th>Balance</th>
                            <th>Transaction Period</th>
                            <th>Origin</th>
                            <th
                              onClick={() =>
                                handleSort("updatedDate", updatedDateSort)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              Updated On &nbsp;
                              <i
                                className={
                                  updatedDateSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th
                              onClick={() => handleSort("status", statusSort)}
                              style={{ cursor: "pointer" }}
                            >
                              Status &nbsp;
                              <i
                                className={
                                  statusSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length > 0 ? (
                            currentData.map((trans) => (
                              <tr key={trans.id} className="text-nowrap">
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/transaction/view/Deduction/${trans.id}`,
                                      query: `/transaction`,
                                      search: "?search=" + search,
                                    }}
                                    className="text-primary"
                                    title={"View Transaction Details"}
                                  >
                                    {trans.emp_no}
                                  </Link>
                                </td>
                                <td
                                  className={
                                    trans.emp_status == 0 ? "text-danger" : ""
                                  }
                                >
                                  {trans.last_name + ", " + trans.first_name}
                                  {trans.emp_status == 0 ? (
                                    <>
                                      <br />
                                      (INACTIVE)
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{trans.ed_code + " - " + trans.ed_name}</td>

                                <td>{trans.pay_cycle_name}</td>
                                <td className="text-right">
                                  {trans.amount
                                    .toLocaleString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td className="text-right">
                                  {trans.per_payment
                                    .toLocaleString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td className="text-right">
                                  {trans.balance
                                    .toLocaleString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td
                                  className={
                                    currentPaySched.id > trans.to_date
                                      ? trans.status_name == "OPEN"
                                        ? " text-danger"
                                        : ""
                                      : ""
                                  }
                                >
                                  {trans.ps_from_code +
                                    " to " +
                                    trans.ps_to_code}
                                  {currentPaySched.id > trans.to_date
                                    ? trans.status_name == "OPEN"
                                      ? " (OUTDATED)"
                                      : ""
                                    : ""}
                                </td>
                                <td>{trans.origin}</td>
                                <td>
                                  {dateFormatter(
                                    trans.updated_at,
                                    "MMM dd, yyyy hh:mm bb"
                                  )}
                                </td>
                                <td>
                                  <strong
                                    className={
                                      "text-" + textColor[trans.status]
                                    }
                                  >
                                    {trans.status_name}
                                  </strong>
                                </td>
                                <td>
                                  <div className="btn-group">
                                    <Link
                                      to={{
                                        pathname: `/transaction/view/Deduction/${trans.id}`,
                                        query: `/transaction`,
                                        search: "?search=" + search,
                                      }}
                                      className="btn btn-sm btn-outline-primary"
                                      title={"View Transaction Details"}
                                    >
                                      <FindInPage />
                                    </Link>
                                    {trans.status == 0 ? (
                                      <>
                                        {permissions > 2 ? (
                                          <EditTransaction
                                            transDetail={trans}
                                            edDD={edDD}
                                            empDD={optionData}
                                            paySchedFrom={fromData}
                                            paySchedTo={toData}
                                            type={"Deduction"}
                                            parentCallback={handleAddCallback}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {permissions > 3 ? (
                                          <CloseTransaction
                                            transId={trans.id}
                                            parentCallback={handleAddCallback}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {trans.status == 1 ? (
                                      permissions > 2 ? (
                                        <>
                                          <HoldTransaction
                                            transId={trans.id}
                                            parentCallback={handleAddCallback}
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}

                                    {trans.status == 4 ? (
                                      permissions > 2 ? (
                                        <>
                                          <ContinueTransaction
                                            transId={trans.id}
                                            parentCallback={handleAddCallback}
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}

                                    {trans.status < 5 &&
                                    trans.status != 0 &&
                                    trans.status != 2 &&
                                    trans.status != 3 ? (
                                      permissions > 3 ? (
                                        <>
                                          <StopTransaction
                                            transId={trans.id}
                                            parentCallback={handleAddCallback}
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="11" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <ul class="pagination float-right">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Transactions
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
