import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { CloudUpload } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class UploadAttendance extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddModal: false,
      companyDD: [],
      hasError: false,
      disableAdd: false,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  getDateValue = (type) => {
    const d = new Date();
    let monthd = d.getMonth() + 1;
    let month = monthd;
    if (monthd < 10) month = "0" + monthd;
    let year = d.getFullYear();
    // let day = d.getDate;
    let date = year + "-" + month + "-01";
    let lastDay = new Date(year, monthd, 0);
    let ld = lastDay.getDate();
    if (parseInt(lastDay.getDate()) < 10) ld = "0" + lastDay.getDate();

    // if (cookies.get("transPay") == 0) {
    // } else if (cookies.get("transPay") == 1) {
    //   axios.get("api/uploads/" + cookies.get("transCompany")).then((res) => {
    //     if (res.data.length > 0) {
    //       let checker = year + "-" + month + "-16";
    //       if (res.data[0].co_from_date >= checker) {
    //         monthd = d.getMonth() + 2;
    //         month = monthd;
    //         if (monthd < 10) month = "0" + monthd;
    //         lastDay = new Date(year, monthd, 0);
    //         ld = lastDay.getDate();
    //         if (parseInt(lastDay.getDate()) < 10) ld = "0" + lastDay.getDate();
    //       }
    //       if (type == "from") {
    //         if (date >= res.data[0].co_from_date)
    //           this.setState({ co_from_date: year + "-" + month + "-16" });
    //         else this.setState({ co_from_date: year + "-" + month + "-01" });
    //       } else {
    //         if (date >= res.data[0].co_from_date)
    //           this.setState({
    //             co_to_date: year + "-" + month + "-" + ld,
    //           });
    //         else this.setState({ co_to_date: year + "-" + month + "-15" });
    //       }
    //     } else {
    //       if (type == "from") {
    //         this.setState({ co_from_date: date });
    //       } else {
    //         this.setState({ co_to_date: year + "-" + month + "-15" });
    //       }
    //     }
    //   });
    // } else if (cookies.get("transPay") == 2) {
    // } else if (cookies.get("transPay") == 3) {
    // }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableAdd: true });
    let formData = new FormData();
    formData.append("uploadFile", this.state.actual_file);
    formData.append("co_from_date", this.state.co_from_date);
    formData.append("co_to_date", this.state.co_to_date);
    formData.append("description", this.state.description);
    formData.append("file_name", this.state.file_name);
    formData.append("company_id", cookies.get("transCompany"));
    formData.append("updated_by", cookies.get("email"));
    formData.append("created_by", cookies.get("email"));
    axios
      .post("api/upload", formData)
      .then((res) => {
        this.setState({
          disableAdd: false,
          showAddModal: false,
          hasError: false,
        });
        this.props.parentCallback(true);
        // window.location.reload();
      })
      .catch((err) => {
        this.setState({ disableAdd: false, hasError: true });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm"
          title="Upload Timesheet"
          onClick={this.handleshowAddModal}
        >
          <CloudUpload /> Upload Timesheet
        </button>
        <Modal
          size="md"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Upload Timesheet</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Cutoff period already uploaded, please see at "View Timesheet"
                  Page
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Description</label>
                  <input
                    type="text"
                    placeholder="Description"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({
                        description: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Period From</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    defaultValue={
                      this.state.co_from_date
                        ? this.state.co_from_date
                        : this.getDateValue("from")
                    }
                    onChange={(e) =>
                      this.setState({
                        co_from_date: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Period To</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    defaultValue={
                      this.state.co_to_date
                        ? this.state.co_to_date
                        : this.getDateValue("to")
                    }
                    onChange={(e) =>
                      this.setState({
                        co_to_date: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>File</label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".xlsx,.xls"
                    required
                    onChange={(e) =>
                      this.setState({
                        actual_file: e.target.files[0],
                        file_name: e.target.files[0].name,
                      })
                    }
                  />
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableAdd}
                >
                  Save{" "}
                  {this.state.disableAdd ? (
                    <span
                      class="spinner spinner-border spinner-border-sm"
                      id="spinner"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
