import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { timeFormatter, dateFormatter } from "../../components/DtFormatter";
import Header from "../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const textColor = ["success", "warning", "success", "danger", "info", "danger"];
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

class ViewTransaction extends Component {
  state = {
    permissions: cookies.get("permissions")["transaction"],
    transactionData: [],
    detailData: [],
  };
  constructor() {
    super();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getDetails();
  }

  componentDidUpdate() {
    if (this.state.shiftEdited) {
      this.getDetails();
      this.setState({ shiftEdited: false });
    }
  }

  getDetails = () => {
    const TransactionId = this.props.match.params.id;
    axios.get("api/transaction_view/" + TransactionId).then((res) => {
      this.setState({ transactionData: res.data[0] });
    });

    axios.get("api/transaction_detail/" + TransactionId).then((res) => {
      this.setState({ detailData: res.data });
    });
  };

  handleBack() {
    this.props.history.goBack();
  }

  handleEditShiftCallback = (cb) => {
    if (cb) {
      this.setState({ shiftEdited: true });
    }
  };

  

  render() {
    return (
      <div className="hk-wrapper hk-vertical-nav">
        <div class="pg-hk-wrapper">
          <Header />
          <div className="mt-20 hk-pg-wrapper">
            {this.state.permissions > 0 ? (
              <div className="container-fluid">
                <div className="row mb-10">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Transaction View</h3>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {/* <button
                      className="btn btn-sm btn-danger float-right "
                      onClick={this.handleBack}
                    >
                      Back to Previous Page
                    </button> */}

                    <Link
                      to={{
                        pathname: this.props.match.params.type == 'Deduction' ? `/transaction_ded` :
                         this.props.match.params.type == 'OT' ? `/transaction_ot` : `/transaction`,
                        query: `/transaction`,
                        search: "?search="+this.props.location.search.split("=").pop()
                      }}
                      className="btn btn-sm btn-danger float-right "
                      title={"View Transaction Details"}
                    >
                      Back to Previous Page
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="card-header card-header-action">
                        <h5>Transaction Details</h5>
                        <div className="d-flex align-items-center card-action-wrap-tools"></div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Employee Name</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.last_name +
                                  ", " +
                                  this.state.transactionData.first_name}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Transaction Period</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.ps_from_code +
                                  " to " +
                                  this.state.transactionData.ps_to_code}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Pay Period Release</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.pay_cycle_name}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Total Amount</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {(this.state.transactionData.amount + "")
                                  .toLocaleString()
                                  .replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Per Payment Period Amount</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {(this.state.transactionData.per_payment + "")
                                  .toLocaleString()
                                  .replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                              </div>
                              <div className="col-xl-6 col-sm-12">
                                <strong>Balance</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12">
                                :&nbsp;&nbsp;&nbsp;
                                {(this.state.transactionData.balance + "")
                                  .toLocaleString()
                                  .replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>{this.props.match.params.type}</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.ed_name}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Status</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                <strong
                                  className={
                                    "text-" +
                                    textColor[this.state.transactionData.status]
                                  }
                                >
                                  {this.state.transactionData.status_name}
                                </strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Created by</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.created_by}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Created on</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {dateFormatter(
                                  this.state.transactionData.created_at,
                                  "MMM dd, yyyy hh:mm bb"
                                )}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Updated by</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.updated_by}
                              </div>
                              <div className="col-xl-6 col-sm-12">
                                <strong>Updated on</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12">
                                :&nbsp;&nbsp;&nbsp;
                                {dateFormatter(
                                  this.state.transactionData.updated_at,
                                  "MMM dd, yyyy hh:mm bb"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12 mb-10">
                    <h4>Transaction Summary</h4>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div class="table-responsive">
                      <table class="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center">
                            <th>Pay Period ID</th>
                            <th>Pay Period From/To</th>
                            <th>Per Pay Period Amount</th>
                            <th>Paid</th>
                            <th>Posting Date</th>
                            <th>Posted By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.detailData.length > 0 ? (
                            this.state.detailData.map((detail) => (
                              <tr key={detail.id}>
                                <td className="text-center">{detail.code}</td>
                                <td>
                                  {dateFormatter(
                                    detail.period_from,
                                    "MMM dd, yyyy"
                                  )}{" "}
                                  to{" "}
                                  {dateFormatter(
                                    detail.period_to,
                                    "MMM dd, yyyy"
                                  )}
                                </td>
                                <td className="text-right">
                                  {detail.amount
                                    .toLocaleString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td className="text-center">
                                  {detail.paid == 1 ? "YES" : "NO"}
                                </td>
                                <td>
                                  {dateFormatter(
                                    detail.created_at,
                                    "MMM dd, yyyy hh:mm bb"
                                  )}
                                </td>
                                <td>{detail.created_by}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="5" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <h1 className="m-0 text-center">
                  Your credentials is not sufficient to access this:
                  <br />
                </h1>
                <br />
                <h2 className="text-center">
                  Module : Transactions
                  <br />
                  Access Type : {access[this.state.permissions]}
                  <br />
                  Please contact your Payroll Administrator.
                  <br />
                  <a className="btn btn-primary btn-sm" href="/#/app">
                    Back to Dashboard
                  </a>
                </h2>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ViewTransaction);
