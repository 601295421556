import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const empStatus = ["Probationary", "Regular", "Resigned"];
export default class EditEmpSalary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updated_by: cookies.get("email"),
      showEditModal: false,
    };

    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({
      basic_rate: this.props.empDetail.basic_rate,
      prev_basic_rate: this.props.empDetail.basic_rate,
      hour_rate: this.props.empDetail.hour_rate,
      prev_hour_rate: this.props.empDetail.hour_rate,
      bschema_id: this.props.empDetail.bschema_id,
      schema_id: this.props.empDetail.schema_id,
      month_days: this.props.empDetail.month_days,
      working_hours: this.props.empDetail.working_hours,
    });
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("api/employee_sal/" + this.props.empDetail.id, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showEditModal: false });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title="Edit Employee Salary Details"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Edit Employee Salary Details</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Monthly Basic</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({
                        basic_rate: e.target.value,
                        hour_rate:
                          e.target.value /
                          this.props.empDetail.month_days /
                          this.props.empDetail.working_hours,
                      })
                    }
                    defaultValue={this.props.empDetail.basic_rate}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>With Regular Holiday Pay</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ reg_holiday_pay: e.target.value });
                    }}
                    defaultValue={this.props.empDetail.reg_holiday_pay}
                  >
                    <option value="">Select...</option>
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </select>
                </div>

                <div className="form-group col-md-12 col-sm-12">
                  <label>With Special Holiday Pay</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ spl_holiday_pay: e.target.value });
                    }}
                    defaultValue={this.props.empDetail.spl_holiday_pay}
                  >
                    <option value="">Select...</option>
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </select>
                </div>

                <div className="form-group col-md-12 col-sm-12">
                  <label>Tardiness On?</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ tardiness_on: e.target.value });
                    }}
                    defaultValue={this.props.empDetail.tardiness_on}
                  >
                    <option value="">Select...</option>
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </select>
                </div>

                <div className="form-group col-md-12 col-sm-12">
                  <label>Undertime On?</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ undertime_on: e.target.value });
                    }}
                    defaultValue={this.props.empDetail.undertime_on}
                  >
                    <option value="">Select...</option>
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </select>
                </div>

                <div className="form-group col-md-12 col-sm-12">
                  <label>Basic Pay Schema</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({
                        bschema_id: e.target.value,
                      })
                    }
                    defaultValue={this.props.empDetail.bschema_id}
                  >
                    <option value="">Select Schema</option>
                    {this.props.bschemaDD.map((schema) => (
                      <option key={schema.id} value={schema.id}>
                        {schema.description}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Overtime Pay Schema</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ schema_id: e.target.value })
                    }
                    defaultValue={this.props.empDetail.schema_id}
                  >
                    <option value="">Select Schema</option>
                    {this.props.schemaDD.map((schema) => (
                      <option key={schema.id} value={schema.id}>
                        {schema.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2">
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
