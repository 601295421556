import React, { Component, useEffect, useState } from "react";
import Header from "../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { FindInPage } from "@material-ui/icons";
import { dateFormatter } from "../../components/DtFormatter";
import ExportTimesheetStgEmp from "./ExportTimesheetStgEmp";
import ExportTimesheetStgList from "./ExportTimesheetStgList";
const access = ["For Review", "View Only", "Add", "Add / Edit", "Full Access"];
const status = ["FOR REVIEW", "POSTED"];
const cookies = new Cookies();

export default function TimesheetStagingEmp(props) {
  const permissions = cookies.get("permissions")["timesheet"];
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [fileLoaded, setFileLoaded] = useState(false);

  const [isChecked, setIsChecked] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/stg_attendance_emp/" + props.match.params.stg_id;
      const res = await axios.get(apiLink);
      setData(res.data);
      setFilteredData(res.data);

      let getDefaultChecks = [];
      res.data.map((pr_data) =>
        getDefaultChecks.push({
          id: pr_data.id,
          employee_id: pr_data.employee_id,
          checked: false,
        })
      );
      setIsChecked(getDefaultChecks);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleBack = () => {
    props.history.goBack();
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !data["full_name"]
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const checkAllRows = (e) => {
    let getChecks = [];
    isChecked.map((checks) => {
      getChecks.push({
        id: checks.id,
        employee_id: checks.employee_id,
        checked: !checkAll,
      });
    });
    setIsChecked(getChecks);
    setCheckAll(!checkAll);
  };

  const handleCheck = (e) => {
    let getChecks = [],
      totalChecks = 0;

    isChecked.map((checks) => {
      if (checks.employee_id == e.target.value) {
        getChecks.push({
          id: checks.id,
          employee_id: checks.employee_id,
          checked: !checks.checked,
        });
      } else
        getChecks.push({
          id: checks.id,
          employee_id: checks.employee_id,
          checked: checks.checked,
        });

      if (getChecks.checked) {
        totalChecks++;
      }
    });

    setIsChecked(getChecks);

    if (totalChecks == filteredData.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div className="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row mb-10">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <h3>Timesheet Per Employee</h3>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="btn-group float-right">
                    <button
                      className="btn btn-sm btn-danger float-right "
                      onClick={handleBack}
                    >
                      Back to Previous Page
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 mt-10">
                  <div className="table-wrap">
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <label>
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={handleRecordPerPage}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>{" "}
                        items
                      </div>

                      <div className="col-md-6">
                        <input
                          type="search"
                          className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                          placeholder="Search"
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-md-12">
                        <ExportTimesheetStgEmp
                          dataCheck={isChecked}
                          data={filteredData}
                          stg_id={props.match.params.stg_id}
                        />
                        <ExportTimesheetStgList
                          dataCheck={isChecked}
                          data={filteredData}
                          stg_id={props.match.params.stg_id}
                        />
                      </div>
                    </div>
                    <div className="table-responsive mb-10">
                      <table className="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center text-nowrap">
                            <th>
                              <input
                                type="checkbox"
                                onChange={checkAllRows}
                                checked={checkAll}
                              />
                            </th>
                            <th>Employee #</th>
                            <th>Name</th>
                            <th>Hourly<br />Rate</th>
                            <th>
                              Working Day
                              <br />
                              (Days)
                            </th>
                            <th>
                              Leaves
                              <br />
                              (Days)
                            </th>
                            <th>
                              Rest Day
                              <br />
                              (Days)
                            </th>
                            <th>
                              Absences
                              <br />
                              (Days)
                            </th>
                            <th>
                              Holiday
                              <br />
                              (Days)
                            </th>
                            <th>
                              Overtime
                              <br />
                              (Hours)
                            </th>
                            <th>
                              Night Diff
                              <br />
                              (Hours)
                            </th>
                            <th>
                              Undertime
                              <br />
                              (Hours)
                            </th>
                            <th>
                              Tardiness
                              <br />
                              (Hours)
                            </th>
                            <th>Total<br />Earnings</th>
                            <th>Total<br />Deductions</th>
                            <th>Total<br />Amount</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length > 0 ? (
                            currentData.map((row) => (
                              <tr key={row.id} className="text-nowrap">
                                <td className="text-center">
                                  <input
                                    type="checkbox"
                                    name="payroll_data"
                                    checked={
                                      isChecked.length > 0
                                        ? isChecked.find(
                                            (x) =>
                                              x.employee_id == row.employee_id
                                          ).checked
                                        : false
                                    }
                                    onChange={handleCheck}
                                    value={row.employee_id}
                                  />
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/timesheet_stg/peremp/${row.stg_id}/${row.employee_id}`,
                                      query: `/timesheet_stg`,
                                    }}
                                    className="text-info"
                                    title={"View Employee Timesheet Details"}
                                  >
                                    {row.emp_no}
                                  </Link>
                                </td>
                                <td>{row.last_name + ", " + row.first_name}</td>
                                <td className="text-center">
                                  {row.hour_rate == 0 ||
                                  row.hour_rate == null ||
                                  row.hour_rate == ""
                                    ? "-"
                                    : row.hour_rate_f}
                                </td>
                                <td className="text-center">
                                  {row.working_days == 0 ||
                                  row.working_days == null ||
                                  row.working_days == ""
                                    ? "-"
                                    : row.working_days}
                                </td>
                                <td className="text-center">
                                  {row.leaves == 0 ||
                                  row.leaves == null ||
                                  row.leaves == ""
                                    ? "-"
                                    : row.leaves}
                                </td>
                                <td className="text-center">
                                  {row.rest_days == 0 ||
                                  row.rest_days == null ||
                                  row.rest_days == ""
                                    ? "-"
                                    : row.rest_days}
                                </td>
                                <td className="text-center">
                                  {row.absences == 0 ||
                                  row.absences == null ||
                                  row.absences == ""
                                    ? "-"
                                    : row.absences}
                                </td>
                                <td className="text-center">
                                  {row.holidays == 0 ||
                                  row.holidays == null ||
                                  row.holidays == ""
                                    ? "-"
                                    : row.holidays}
                                </td>
                                <td className="text-right">
                                  {row.overtime == 0 ||
                                  row.overtime == null ||
                                  row.overtime == ""
                                    ? "-"
                                    : row.overtime_f}
                                </td>
                                <td className="text-right">
                                  {row.nd_hours == 0 ||
                                  row.nd_hours == null ||
                                  row.nd_hours == ""
                                    ? "-"
                                    : row.nd_hours_f}
                                </td>
                                <td className="text-right">
                                  {row.undertime == 0 ||
                                  row.undertime == null ||
                                  row.undertime == ""
                                    ? "-"
                                    : row.undertime_f}
                                </td>
                                <td className="text-right">
                                  {row.tardiness == 0 ||
                                  row.tardiness == null ||
                                  row.tardiness == ""
                                    ? "-"
                                    : row.tardiness_f}
                                </td>
                                <td className="text-right">
                                  {row.earning == 0 ||
                                  row.earning == null ||
                                  row.earning == ""
                                    ? "-"
                                    : row.earning_f}
                                </td>
                                <td className="text-right">
                                  {row.deduction == 0 ||
                                  row.deduction == null ||
                                  row.deduction == ""
                                    ? "-"
                                    : row.deduction_f}
                                </td>
                                <td className="text-right">
                                  {row.net_amt == 0 ||
                                  row.net_amt == null ||
                                  row.net_amt == ""
                                    ? "-"
                                    : row.net_amt_f}
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/timesheet_stg/peremp/${row.stg_id}/${row.employee_id}`,
                                      query: `/timesheet_stg`,
                                    }}
                                    className="btn btn-sm btn-outline-primary"
                                    title={"View Employee Timesheet Details"}
                                  >
                                    <FindInPage />
                                  </Link>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="13" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <ul className="pagination float-right">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a className="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Timesheets
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
