import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { CloudUpload, PersonAdd } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import { FileUploader } from "react-drag-drop-files";
const cookies = new Cookies();

export default class UploadEmployee extends Component {
  constructor(props) {
    super();
    this.state = {
      companyDD: [],
      showAddModal: false,
      showCheckModal: false,
      showSuccessModal: false,
      hasError: false,
      hasWarning: false,
      disableAdd: false,
      checkData: [],
      err_no: 0,
      rec_no: 0,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({
      showAddModal: !this.state.showAddModal,
      disableAdd: false,
      hasError: false,
      hasWarning: false,
      actual_file: null,
      file_name: null,
    });
  };

  handleshowAddModal = (e) => {
    this.setState({
      showAddModal: !this.state.showAddModal,
      disableAdd: false,
      hasError: false,
      hasWarning: false,
      actual_file: null,
      file_name: null,
    });
  };

  handleshowCheckModal = (e) => {
    this.setState({
      showCheckModal: !this.state.showCheckModal,
      disableAdd: false,
      hasError: false,
      hasWarning: false,
    });
  };

  handleCheck = (e) => {
    e.preventDefault();
    // this.setState({ disableAdd: true });
    let formData = new FormData();
    formData.append("uploadFile", this.state.actual_file);
    formData.append("file_name", this.state.file_name);
    formData.append("description", this.state.description);
    formData.append("record_year", this.state.record_year);
    formData.append("company_id", cookies.get("transCompany"));
    formData.append("updated_by", cookies.get("email"));
    formData.append("created_by", cookies.get("email"));

    axios.post("api/upload_employee_check", formData).then((res) => {
      this.setState({
        disableAdd: false,
        showAddModal: false,
        showCheckModal: true,
        checkData: res.data.rowData,
        rec_no: res.data.rows,
        err_no: res.data.errs.length,
      });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableAdd: true });
    let formData = new FormData();
    formData.append("uploadFile", this.state.actual_file);
    formData.append("file_name", this.state.file_name);
    formData.append("created_by", cookies.get("email"));
    axios
      .post("api/upload_employee", formData)
      .then((res) => {
        this.props.parentCallback(true);

        if (res.data.errors) {
          this.setState({
            disableAdd: true,
            hasError: true,
            showAddModal: false,
          });

          const element = document.createElement("a");
          const file = new Blob(res.data.errors, {
            type: "text/plain",
          });
          element.href = URL.createObjectURL(file);
          element.download = this.state.file_name + "_errors.txt";
          document.body.appendChild(element);
          alert("Failed to upload file see downloaded .txt for reference");
          element.click();
        } else {
          this.setState({
            disableAdd: false,
            hasError: false,
            showAddModal: false,
            showCheckModal: false,
            showSuccessModal: true,
            actual_file: null,
            file_name: null,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ disableAdd: false, hasError: true });
      });
  };

  handleChange = (file) => {
    this.setState({ actual_file: file, file_name: file.name });
  };

  ErrCancel = (e) => {
    e.preventDefault();
    this.setState({
      disableAdd: false,
      showAddModal: false,
      showCheckModal: false,
      showSuccessModal: false,
      hasError: false,
      actual_file: null,
      file_name: null,
    });
    this.props.parentCallback(true);
  };

  render() {
    return (
      <>
        <button
          className="dropdown-item"
          title="Upload Employees"
          onClick={this.handleshowAddModal}
        >
          <PersonAdd /> Upload Employees
        </button>
        <Modal
          size="md"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Upload Employees</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Employees Upload Failed
                </div>
              </div>
            </div>
            <form onSubmit={this.handleCheck} id="contact-form">
              <div className="row">
                {/* <div className="form-group col-md-12 col-sm-12">
                  <label>Description</label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => {
                      this.setState({
                        description: e.target.value,
                      });
                    }}
                    required
                  />
                </div> */}
                <div className="form-group col-md-12 col-sm-12">
                  <label>Employee File</label>
                  <FileUploader
                    multiple={false}
                    handleChange={this.handleChange}
                    types={["XLSX"]}
                    classes="dropzone drop_area"
                  />
                  {this.state.actual_file ? (
                    <div
                      className="alert alert-success font-weight-bold"
                      role="alert"
                    >
                      Uploaded File name: {this.state.file_name}
                    </div>
                  ) : (
                    <div className="alert alert-danger" role="alert">
                      No File Uploaded
                    </div>
                  )}
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableAdd}
                >
                  Proceed
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          size="xl"
          show={this.state.showCheckModal}
          backdrop="static"
          onHide={() =>
            this.setState({ showCheckModal: !this.state.showCheckModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Upload Employee: Checking</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="alert alert-info">
                  <strong>File:</strong> {this.state.file_name}
                  <br />
                  <strong>Total Records:</strong> {this.state.rec_no}
                  <br />
                  <strong>Total Errors:</strong> {this.state.err_no}
                  <br />
                </div>
              </div>
            </div>
            <div class="table-responsive mb-10">
              <table class="table table-xs table-bordered mb-0">
                <thead className="thead-secondary">
                  <tr className="text-center">
                    <th>#</th>
                    <th className="text-nowrap">Company Code</th>
                    <th className="text-nowrap">First Name</th>
                    <th className="text-nowrap">Middle Name</th>
                    <th className="text-nowrap">Last Name</th>
                    <th className="text-nowrap">Emp #</th>
                    <th className="text-nowrap">Department</th>
                    <th className="text-nowrap">Position</th>
                    <th className="text-nowrap">Date of Birth</th>
                    <th className="text-nowrap">Company Email</th>
                    <th className="text-nowrap">Contact #</th>
                    <th className="text-nowrap">Date Hired</th>
                    <th className="text-nowrap">SSS #</th>
                    <th className="text-nowrap">PhilHealth #</th>
                    <th className="text-nowrap">PAGIBIG #</th>
                    <th className="text-nowrap">TIN #</th>
                    <th className="text-nowrap">Bank Code</th>
                    <th className="text-nowrap">Bank Account #</th>
                    <th className="text-nowrap">Monthly Basic</th>
                    <th className="text-nowrap">Deminimis</th>
                    <th className="text-nowrap">Basic Schema Code</th>
                    <th className="text-nowrap">OT Schema Code</th>
                    <th className="text-nowrap">With Regular Holiday Pay</th>
                    <th className="text-nowrap">With Special Holiday Pay</th>
                    <th className="text-nowrap">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.checkData.map((row) => (
                    <tr key={row.number}>
                      <td>{row.number}</td>
                      <td className={row.err1 == 1 ? "bg-danger" : ""}>
                        {row.company_code}
                        {row.err25 == 1? <strong><br/>Not the selected company</strong> : "" }
                        {row.err1 == 1 && row.err25 == 0 ? <strong><br/>Code doesn't exist in DB</strong> : "" }
                      </td>
                      <td className={row.err2 == 1 ? "bg-danger" : ""}>
                        {row.first_name}
                      </td>
                      <td className={row.err3 == 1 ? "bg-danger" : ""}>
                        {row.mid_name}
                      </td>
                      <td className={row.err4 == 1 ? "bg-danger" : ""}>
                        {row.last_name}
                      </td>
                      <td className={row.err5 == 1 ? "bg-danger" : ""}>
                        {row.emp_no}
                      </td>
                      <td className={row.err6 == 1 ? "bg-danger" : ""}>
                        {row.department}
                      </td>
                      <td className={row.err7 == 1 ? "bg-danger" : ""}>
                        {row.position}
                      </td>
                      <td className={row.err8 == 1 ? "bg-danger" : ""}>
                        {row.date_of_birth}
                      </td>
                      <td className={row.err9 == 1 ? "bg-danger" : ""}>
                        {row.company_email}
                      </td>
                      <td className={row.err10 == 1 ? "bg-danger" : ""}>
                        {row.contact_number}
                      </td>
                      <td className={row.err11 == 1 ? "bg-danger" : ""}>
                        {row.date_hired}
                      </td>
                      <td className={row.err12 == 1 ? "bg-danger" : ""}>
                        {row.sss_no}
                      </td>
                      <td className={row.err13 == 1 ? "bg-danger" : ""}>
                        {row.philhealth_no}
                      </td>
                      <td className={row.err14 == 1 ? "bg-danger" : ""}>
                        {row.hmdf_no}
                      </td>
                      <td className={row.err15 == 1 ? "bg-danger" : ""}>
                        {row.tin_no}
                      </td>
                      <td className={row.err16 == 1 ? "bg-danger" : ""}>
                        {row.bank_id}
                      </td>
                      <td className={row.err17 == 1 ? "bg-danger" : ""}>
                        {row.bank_account_no}
                      </td>
                      <td
                        className={
                          row.err18 == 1
                            ? "bg-danger text-right"
                            : " text-right"
                        }
                      >
                        {row.basic_rate}
                      </td>
                      <td
                        className={
                          row.err19 == 1
                            ? "bg-danger text-right"
                            : " text-right"
                        }
                      >
                        {row.deminimis}
                      </td>
                      <td className={row.err22 == 1 ? "bg-danger" : ""}>
                        {row.bschema_code}
                      </td>
                      <td className={row.err23 == 1 ? "bg-danger" : ""}>
                        {row.schema_code}
                      </td>
                      <td className={row.err22 == 1 ? "bg-danger" : ""}>
                        {row.reg_holiday_pay}
                      </td>
                      <td className={row.err23 == 1 ? "bg-danger" : ""}>
                        {row.spl_holiday_pay}
                      </td>
                      <td className={row.err24 == 1 ? "bg-danger" : ""}>
                        {row.emp_status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {this.state.err_no > 0 ? (
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="alert alert-danger">
                    {this.state.err_no} Invalid record(s) found, please correct
                    the data and re-upload
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.err_no > 0 ? (
              <div className="float-right">
                <button
                  onClick={this.ErrCancel}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="float-right">
                <button
                  onClick={this.handleSubmit}
                  className="btn btn-primary btn-sm mr-2"
                  type="button"
                  disabled={this.state.disableAdd}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowCheckModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={this.state.showSuccessModal}
          backdrop="static"
          onHide={() =>
            this.setState({ showSuccessModal: !this.state.showSuccessModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Upload Employee: Successful</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="alert alert-success">
                  <strong>Import Result</strong>
                  <br />
                  <strong>Total Records:</strong> {this.state.rec_no}
                  <br />
                  <strong>Total Errors:</strong> {this.state.err_no}
                  <br />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="float-right">
              <button
                onClick={() =>
                  this.setState({
                    showSuccessModal: !this.state.showSuccessModal,
                  })
                }
                className="btn btn-danger btn-sm"
                type="button"
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
