import React, { Component, useEffect, useState } from "react";
import Header from "../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { FindInPage } from "@material-ui/icons";
import { dateFormatter, timeFormatter } from "../../components/DtFormatter";
import multiColumnSort from "multi-column-sort";
const access = ["For Review", "View Only", "Add", "Add / Edit", "Full Access"];
const status = ["FOR REVIEW", "POSTED"];
const cookies = new Cookies();

export default function TimesheetStagingDetail(props) {
  const permissions = cookies.get("permissions")["timesheet"];
  const [data, setData] = useState([]);
  const [emp, setEmp] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [dateInSort, setDateInSort] = useState("DESC");
  const [dateOutSort, setDateOutSort] = useState("DESC");
  const [dayTypeSort, setDayTypeSort] = useState("DESC");
  const [modifierSort, setModifierSort] = useState("DESC");
  useEffect(() => {
    const fetchData = async () => {
      let apiLink =
        "api/stg_attendance_detail/" +
        props.match.params.stg_id +
        "/" +
        props.match.params.emp_id;
      const res = await axios.get(apiLink);
      setData(res.data.timesheet);
      setFilteredData(res.data.timesheet);
      setEmp(res.data.employee);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleBack = () => {
    props.history.goBack();
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["day_type"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            data["modifier"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const handleSort = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    if (columnChanged == "date_in") {
      sorter = [["date_in", dateInSort]];

      setDateInSort(setSort);
    }

    if (columnChanged == "date_out") {
      sorter = [["date_out", dateOutSort]];

      setDateOutSort(setSort);
    }

    if (columnChanged == "day_type") {
      sorter = [["day_type", dayTypeSort]];

      setDayTypeSort(setSort);
    }

    if (columnChanged == "modifier") {
      sorter = [["modifier", modifierSort]];

      setModifierSort(setSort);
    }

    const sorted = multiColumnSort(filteredData, sorter, getColumnValue);

    setFilteredData(sorted);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row mb-10">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <h3>Timesheet Employee Detail</h3>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="btn-group float-right">
                    <button
                      className="btn btn-sm btn-danger float-right "
                      onClick={handleBack}
                    >
                      Back to Previous Page
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mb-10">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <h5 className="card-header">Employee Details</h5>
                    <div className="card-body">
                      <div className="row mb-10">
                        <div className="col-xl-2 col-sm-10">
                          <strong>Company</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {emp.company_name}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-2 col-sm-10">
                          <strong>Name</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {emp.first_name} {emp.last_name}
                        </div>
                        <div className="col-xl-2 col-sm-10">
                          <strong>Employee #</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {emp.emp_no}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-2 col-sm-10">
                          <strong>Position</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {emp.position}
                        </div>
                        <div className="col-xl-2 col-sm-10">
                          <strong>Department</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {emp.department}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-2 col-sm-10">
                          <strong>Company Email</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {emp.company_email}
                        </div>
                        <div className="col-xl-2 col-sm-10">
                          <strong>Contact #</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {emp.contact_number}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-10">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <h5 className="card-header">Legend</h5>
                    <div className="card-body">
                      <div className="row mb-10">
                        <div className="col-xl-2 col-sm-10">
                          <strong>Normal Working day</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span
                            style={{ color: "black", backgroundColor: "black" }}
                          >
                            _______________
                          </span>
                        </div>

                        <div className="col-xl-2 col-sm-10">
                          <strong>Holidays</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span
                            style={{ color: "blue", backgroundColor: "blue" }}
                          >
                            _______________
                          </span>
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-2 col-sm-10">
                          <strong>Overtime & Night Diff</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span
                            style={{ color: "purple", backgroundColor: "purple" }}
                          >
                            _______________
                          </span>
                        </div>

                        <div className="col-xl-2 col-sm-10">
                          <strong>Leaves & Restdays</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span
                            style={{ color: "green", backgroundColor: "green" }}
                          >
                            _______________
                          </span>
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-2 col-sm-10">
                          <strong>Tardiness, Undertime & Absences</strong>
                        </div>
                        <div className="col-xl-4 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span
                            style={{ color: "red", backgroundColor: "red" }}
                          >
                            _______________
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 mt-10">
                  <div className="card">
                    <h5 className="card-header">Timesheet</h5>
                    <div className="card-body">
                      <div class="table-wrap">
                        <div class="row mb-10">
                          <div class="col-md-6">
                            <label>
                              <select
                                class="custom-select custom-select-sm form-control form-control-sm"
                                onChange={handleRecordPerPage}
                                defaultValue={recordsPerPage}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </label>{" "}
                            items
                          </div>
                          <div class="col-md-6">
                            <input
                              type="search"
                              class="form-control form-control-sm col-md-6 col-sm-6 float-right"
                              placeholder="Search"
                              onChange={handleSearch}
                            />
                          </div>
                        </div>
                        <div class="table-responsive mb-10">
                          <table class="table table-sm table-bordered table-striped mb-0">
                            <thead className="thead-secondary">
                              {filteredData.length > 0 ? (
                                <tr className="text-center text-nowrap">
                                  <th
                                    className="sticky-col"
                                    style={{ left: "0px", cursor: "pointer" }}
                                    onClick={() =>
                                      handleSort("date_in", dateInSort)
                                    }
                                  >
                                    Date In &nbsp;
                                    <i
                                      className={
                                        dateInSort == "ASC"
                                          ? "fa fa-arrow-down"
                                          : "fa fa-arrow-up"
                                      }
                                    ></i>
                                  </th>
                                  <th
                                    className="sticky-col"
                                    style={{ left: "165px" }}
                                  >
                                    Time In
                                  </th>
                                  <th
                                    className="sticky-col"
                                    style={{ left: "275px", cursor: "pointer" }}
                                    onClick={() =>
                                      handleSort("date_out", dateOutSort)
                                    }
                                  >
                                    Date Out &nbsp;
                                    <i
                                      className={
                                        dateOutSort == "ASC"
                                          ? "fa fa-arrow-down"
                                          : "fa fa-arrow-up"
                                      }
                                    ></i>
                                  </th>
                                  <th
                                    className="sticky-col "
                                    style={{ left: "440px" }}
                                  >
                                    Time Out
                                  </th>
                                  <th>Scheduled In</th>
                                  <th>Scheduled Out</th>
                                  <th
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleSort("day_type", dayTypeSort)
                                    }
                                  >
                                    Day Type &nbsp;
                                    <i
                                      className={
                                        dayTypeSort == "ASC"
                                          ? "fa fa-arrow-down"
                                          : "fa fa-arrow-up"
                                      }
                                    ></i>
                                  </th>
                                  <th
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleSort("modifier", modifierSort)
                                    }
                                  >
                                    Modifier &nbsp;
                                    <i
                                      className={
                                        modifierSort == "ASC"
                                          ? "fa fa-arrow-down"
                                          : "fa fa-arrow-up"
                                      }
                                    ></i>
                                  </th>

                                  <th>Earning/Deduction Code</th>
                                  <th>Hourly Rate</th>
                                  <th>Hours Rendered</th>
                                  <th>Premium Rate</th>
                                  <th>Amount</th>
                                </tr>
                              ) : (
                                <tr className="text-center text-nowrap">
                                  <th>Date In</th>
                                  <th>Time In</th>
                                  <th>Date Out</th>
                                  <th>Time Out</th>
                                  <th>Scheduled In</th>
                                  <th>Scheduled Out</th>
                                  <th>Day Type</th>
                                  <th>Modifier</th>
                                  <th>Earning/Deduction Code</th>
                                  <th>Hourly Rate</th>
                                  <th>Hours Rendered</th>
                                  <th>Premium Rate</th>
                                  <th>Amount</th>
                                </tr>
                              )}
                            </thead>
                            <tbody>
                              {filteredData.length > 0 ? (
                                currentData.map((row) => (
                                  <tr
                                    key={row.id}
                                    className={
                                      row.text_color != ""
                                        ? "text-nowrap text-" + row.text_color
                                        : "text-nowrap"
                                    }
                                  >
                                    <td className="sticky-col l-0">
                                      {dateFormatter(
                                        row.date_in,
                                        "MMM dd, yyyy EE"
                                      )}
                                    </td>
                                    <td
                                      className="sticky-col"
                                      style={{ left: "165px" }}
                                    >
                                      {timeFormatter(row.time_in)}
                                    </td>
                                    <td
                                      className="sticky-col"
                                      style={{ left: "275px" }}
                                    >
                                      {dateFormatter(
                                        row.date_out,
                                        "MMM dd, yyyy EE"
                                      )}
                                    </td>
                                    <td
                                      className="sticky-col"
                                      style={{ left: "440px" }}
                                    >
                                      {timeFormatter(row.time_out)}
                                    </td>
                                    <td>{row.sched_in}</td>
                                    <td>{row.sched_out}</td>
                                    <td>{row.day_type}</td>
                                    <td>{row.modifier}</td>

                                    <td>{row.code + " - " + row.name}</td>
                                    <td className="text-right">
                                      {row.hourly_rate}
                                    </td>
                                    <td className="text-right">
                                      {row.hours_worked == 0 ||
                                      row.hours_worked == null ||
                                      row.hours_worked == ""
                                        ? "-"
                                        : row.hours_worked}
                                    </td>
                                    <td className="text-right">
                                      {row.premium_rate == 0 ||
                                      row.premium_rate == null ||
                                      row.premium_rate == ""
                                        ? "-"
                                        : Math.round(row.premium_rate * 100) +
                                          " %"}
                                    </td>
                                    <td className="text-right">
                                      {row.amount == 0 ||
                                      row.amount == null ||
                                      row.amount == ""
                                        ? "-"
                                        : row.amount}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="11" className="text-center">
                                    No Data to show
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          {filteredData.length > 0
                            ? "Showing " +
                              (startIndex + 1) +
                              " to " +
                              (endIndex > filteredData.length
                                ? filteredData.length
                                : endIndex) +
                              " of " +
                              filteredData.length
                            : ""}
                        </div>
                        <div class="col-sm-6 col-md-6">
                          {filteredData.length > 0 ? (
                            <ul class="pagination float-right">
                              {pages.map((number) => (
                                <li
                                  key={number}
                                  id={number}
                                  className={
                                    currentPage == number
                                      ? "paginate_button page-item active"
                                      : "paginate_button page-item"
                                  }
                                  onClick={() => setCurrentPage(number)}
                                >
                                  <a class="page-link">{number}</a>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Timesheets
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
