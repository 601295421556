import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Check, CheckCircle } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class TimesheetStgPost extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      company_id: cookies.get("transCompany"),
      disableSubmit: false,
      showModal: false,
    };
    this.handleShowModal = this.handleShowModal.bind(this);
  }

  handleShowModal = (e) => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios.post("api/ts_transaction_post/" + this.props.id, this.state).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showModal: false });
      this.setState({ disableSubmit: true });
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title="Post Transaction Upload"
          onClick={this.handleShowModal}
        >
          <CheckCircle />
        </button>
        <Modal
          size="md"
          show={this.state.showModal}
          onHide={() =>
            this.setState({ showModal: !this.state.showModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Post Timesheet to transaction: {this.props.name}</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  Are you sure?
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Post {this.state.disableSubmit ? <span class="spinner spinner-border spinner-border-sm" id="spinner" role="status" aria-hidden="true">
                    </span> : ""
                    }
                </button>
                <button
                  onClick={this.handleShowModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
