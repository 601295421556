import React, { Component } from "react";
import { withRouter } from "react-router";
import { Refresh } from "@material-ui/icons";
import { timeFormatter, dateFormatter } from "../../components/DtFormatter";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import axios from "axios";
import EditSchedule from "./EditSchedule";
import EditEmployee from "./EditEmployee";
import EditEmpSalary from "./EditEmpSalary";
import Cookies from "universal-cookie";
import EditEdDetail from "./EditEdDetails";
import multiColumnSort from "multi-column-sort";
import Processing from "../../components/Processing";
import EditEmployeeInt from "./EditEmployeeInt";
const cookies = new Cookies();
const setting = cookies.get("setting");
const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];
const empStatus = ["INACTIVE", "ACTIVE"];
const pay_period = [["Weekly", "15th & 30th", "15th", "30th", "13th Month"]];

class ViewEmployee extends Component {
  state = {
    permissions: cookies.get("permissions")["master_data"],
    empDetails: [],
    shiftDetails: [],
    historyData: [],
    salaryData: [],
    shiftEdited: false,
    companyDD: [],
    schemaDD: [],
    bschemaDD: [],
    sssBracket: [],
    taxBracket: [],
    edData: [],
    bankDD: [],
    edFilteredData: [],
    nameSort: "DESC",
    typeSort: "DESC",
    pay_cycle_nameSort: "DESC",
    totalWorkHours: 0,
    historyCurrentPage: 1,
    historyRecordsPerPage: 5,
    refreshDisabled: false,
  };

  constructor(props) {
    super();

    this.handleBack = this.handleBack.bind(this);
  }
  componentDidMount() {
    this.getDetails();
    axios.get("api/companyDD").then((res) => {
      this.setState({ companyDD: res.data });
    });

    axios.get("api/schemaDD").then((res) => {
      this.setState({ schemaDD: res.data });
    });

    axios.get("api/basic_schemaDD2").then((res) => {
      this.setState({ bschemaDD: res.data });
    });
  }

  componentDidUpdate() {
    if (this.state.shiftEdited) {
      this.getDetails();
      this.setState({ shiftEdited: false });
    }
  }

  getDetails = () => {
    axios.get("api/employee_view/" + this.props.match.params.id).then((res) => {
      console.log(res);
      this.setState({
        empDetails: res.data.employee,
        sssBracket: res.data.sssBracket,
        taxBracket: res.data.taxBracket,
        shiftDetails: res.data.shift,
        historyData: res.data.shiftHistory,
        salaryData: res.data.salaryHistory,
        edData: res.data.empEd,
        edFilteredData: res.data.empEd,
        bankDD: res.data.bankDD,
      });
    });
  };

  handleBack() {
    this.props.history.goBack();
  }

  handleEditShiftCallback = (cb) => {
    if (cb) {
      this.setState({ shiftEdited: true });
    }
  };

  handleRefresh = () => {
    let request = {
      basic_rate: this.state.empDetails.basic_rate,
      bschema_id: this.state.empDetails.bschema_id,
      updated_by: cookies.get("email"),
    };

    this.setState({ refreshDisabled: true });
    axios
      .post("api/refreshED/" + this.state.empDetails.id, request)
      .then((res) => {
        setTimeout(
          function () {
            this.setState({ shiftEdited: true, refreshDisabled: false });
          }.bind(this),
          1000
        );
      });
  };

  handleSearchED = (e) => {
    const filterer = this.state.edData.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["name"].toLowerCase().includes(e.target.value.toLowerCase()) ||
            data["type"].toLowerCase().includes(e.target.value.toLowerCase()) ||
            data["pay_cycle_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    this.setState({ edFilteredData: filterer });
  };

  handleSortED = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (columnChanged == "name") {
      sorter = [
        ["name", this.state.nameSort],
        ["type", "ASC"],
        ["pay_cycle_name", "ASC"],
      ];
    } else if (columnChanged == "type") {
      sorter = [
        ["type", this.state.typeSort],
        ["name", "ASC"],
        ["pay_cycle_name", "ASC"],
      ];
    } else if (columnChanged == "pay_cycle_name") {
      sorter = [
        ["pay_cycle_name", this.state.pay_cycle_nameSort],
        ["name", "ASC"],
        ["type", "ASC"],
      ];
    }

    const sorted = multiColumnSort(
      this.state.edFilteredData,
      sorter,
      getColumnValue
    );

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    this.setState({
      edFilteredData: sorted,
      [columnChanged + "Sort"]: setSort,
    });
  };

  render() {
    const pages = [];
    for (
      let i = 1;
      i <=
      Math.ceil(
        this.state.historyData.length / this.state.historyRecordsPerPage
      );
      i++
    ) {
      pages.push(i);
    }

    const startIndex =
      this.state.historyCurrentPage * this.state.historyRecordsPerPage -
      this.state.historyRecordsPerPage;
    const endIndex = startIndex + this.state.historyRecordsPerPage;
    const currentData = this.state.historyData.slice(startIndex, endIndex);

    // console.log(this.props.location.search);
    return (
      <div className="hk-wrapper hk-vertical-nav">
        <div class="pg-hk-wrapper">
          <Header />
          <Processing show={this.state.refreshDisabled} />
          <div className="mt-20 hk-pg-wrapper">
            {this.state.permissions > 0 ? (
              <div className="container-fluid">
                <div className="row mb-10">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Employee View</h3>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {/* <button
                      className="btn btn-sm btn-danger float-right "
                      onClick={this.handleBack}
                    >
                      Back to Previous Page
                    </button> */}
                    <Link
                      to={{
                        pathname: `/employee`,
                        query: `/employee`,
                        search:
                          "?search=" +
                          this.props.location.search.split("=").pop(),
                      }}
                      className="btn btn-sm btn-danger float-right "
                      title={"View Employee Details"}
                    >
                      Back to Previous Page
                    </Link>
                  </div>
                </div>
                <div className="row mb-20">
                  {/* <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <div class="media align-items-center">
                          <div class="media-img-wrapd-flex">
                            <div class="avatar">
                              <img
                                src="https://my247i.com/storage/avatar/GZwEZegPuLZYagyYiUm7lnck7ENBSF7r2rs3V12l.jpeg"
                                alt="user"
                                class="avatar-img rounded-circle"
                              />
                            </div>
                          </div>
                          <div class="media-body ml-4 text-dark">
                            <div class="text-capitalize display-6 mb-5 font-weight-400">
                            {this.state.empDetails.first_name}{" "}
                            {this.state.empDetails.last_name}
                            </div>
                            <div class="font-14">
                              <span class="mr-5">
                                <span class="font-weight-500 pr-5">
                                  Status : {empStatus[this.state.empDetails.emp_status]}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="card-header  card-header-action">
                        <h5>Employee Details</h5>
                        <div className="d-flex align-items-center card-action-wrap-tools">
                          {this.state.permissions > 2 ? (
                            setting.stand_alone > 0 ? (
                              <EditEmployee
                                empDetail={this.state.empDetails}
                                companyDD={this.state.companyDD}
                                bankDD={this.state.bankDD}
                                parentCallback={this.handleEditShiftCallback}
                              />
                            ) : (
                              <EditEmployeeInt
                                empDetail={this.state.empDetails}
                                parentCallback={this.handleEditShiftCallback}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row mb-10">
                          <div className="col-xl-3 col-sm-10">
                            <strong>Name</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.first_name}{" "}
                            {this.state.empDetails.last_name}
                          </div>

                          <div className="col-xl-3 col-sm-10">
                            <strong>Status</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {empStatus[this.state.empDetails.emp_status]}
                          </div>
                        </div>

                        <div className="row mb-10">
                          <div className="col-xl-3 col-sm-10">
                            <strong>Date of Birth</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {dateFormatter(this.state.empDetails.date_of_birth)}
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            <strong>Employee #</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.emp_no}
                          </div>
                          {/* <div className="col-xl-3 col-sm-10">
                            <strong>Company</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.company_name}
                          </div> */}
                        </div>

                        <div className="row mb-10">
                          <div className="col-xl-3 col-sm-10">
                            <strong>Position</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.position}
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            <strong>Department</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.department}
                          </div>
                        </div>

                        <div className="row mb-10">
                          <div className="col-xl-3 col-sm-10">
                            <strong>Company Email</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.company_email}
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            <strong>Contact #</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.contact_number}
                          </div>
                        </div>

                        <div className="row mb-10">
                          <div className="col-xl-3 col-sm-10">
                            <strong>SSS #</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.sss_no}
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            <strong>HMDF #</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.hmdf_no}
                          </div>
                        </div>

                        <div className="row mb-10">
                          <div className="col-xl-3 col-sm-10">
                            <strong>PhilHealth #</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.philhealth_no}
                          </div>

                          <div className="col-xl-3 col-sm-10">
                            <strong>TIN #</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.tin_no}
                          </div>
                        </div>

                        <div className="row mb-10">
                          <div className="col-xl-3 col-sm-10">
                            <strong>Bank</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            <span
                              className={
                                this.state.empDetails.bank_status == "INACTIVE"
                                  ? "text-danger"
                                  : ""
                              }
                            >
                              {this.state.empDetails.bank_code} -{" "}
                              {this.state.empDetails.bank_name}
                              {this.state.empDetails.bank_status == "INACTIVE"
                                ? " (INACTIVE)"
                                : ""}
                            </span>
                          </div>

                          <div className="col-xl-3 col-sm-10">
                            <strong>Bank Account #</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.empDetails.bank_account_no}
                          </div>
                        </div>

                        <div className="row mb-10">
                          <div className="col-xl-3 col-sm-10">
                            <strong>Hire Date</strong>
                          </div>
                          <div className="col-xl-3 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {dateFormatter(this.state.empDetails.date_hired)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div class="card-header p-0 pt-1">
                        <ul
                          class="nav nav-tabs"
                          id="custom-tabs-two-tab"
                          role="tablist"
                        >
                          {/* <li class="nav-item">
                            <a
                              class="nav-link active"
                              id="custom-tabs-two-home-tab"
                              data-toggle="pill"
                              href="#shift-schedule"
                              role="tab"
                              aria-controls="custom-tabs-two-home"
                              aria-selected="true"
                            >
                              <i className="dropdown-icon zmdi zmdi-accounts-alt"></i>
                              &nbsp;Shift Schedule
                            </a>
                          </li> */}

                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              id="custom-tabs-two-home-tab"
                              data-toggle="pill"
                              href="#salary"
                              role="tab"
                              aria-controls="custom-tabs-two-home"
                              aria-selected="true"
                            >
                              <i className="dropdown-icon zmdi zmdi-money"></i>
                              &nbsp;Salary Details
                            </a>
                          </li>

                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="custom-tabs-two-home-tab"
                              data-toggle="pill"
                              href="#earning-deduction"
                              role="tab"
                              aria-controls="custom-tabs-two-home"
                              aria-selected="true"
                            >
                              <i className="dropdown-icon zmdi zmdi-accounts-list"></i>
                              &nbsp;Recurring Earnings &amp; Deductions
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="card-body">
                        <div
                          class="tab-content"
                          id="custom-tabs-two-tabContent"
                        >
                          {/* <div
                            class="tab-pane fade show active"
                            id="shift-schedule"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-two-home-tab"
                          >
                            <div className="row mb-10">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <h5>Shift Schedule</h5>
                              </div>
                            </div>
                            <div class="table-responsive">
                              <table class="table table-sm table-bordered table-striped mb-0">
                                <thead className="thead-secondary">
                                  <tr className="text-center">
                                    <th>Shift Day</th>
                                    <th>From-to</th>
                                    <th>Work Hours</th>
                                    <th style={{ width: "90px" }}>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.shiftDetails.map((shift) => (
                                    <tr key={shift.id}>
                                      <td>{days[shift.sched_day - 1]}</td>
                                      <td>
                                        {shift.is_restday == 1
                                          ? "REST DAY"
                                          : timeFormatter(shift.time_in) +
                                            " - " +
                                            timeFormatter(shift.time_out)}
                                      </td>
                                      <td>{shift.required_work_hours}</td>
                                      <td>
                                        {this.state.permissions > 2 ? (
                                          <EditSchedule
                                            id={shift.id}
                                            emp_id={shift.emp_id}
                                            day={days[shift.sched_day - 1]}
                                            ti={shift.time_in}
                                            to={shift.time_out}
                                            is_restday={shift.is_restday}
                                            parentCallback={
                                              this.handleEditShiftCallback
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <br />
                            <div className="row mb-10">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <h5>Shift History</h5>
                              </div>
                            </div>
                            <div class="table-responsive mb-10">
                              <table class="table table-sm table-bordered table-striped mb-0">
                                <thead className="thead-secondary">
                                  <tr className="text-center">
                                    <th>Shift Day</th>
                                    <th>Previous Value</th>
                                    <th>New Value</th>
                                    <th>Date Changed</th>
                                    <th>Changed By</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {currentData.map((history) => (
                                    <tr key={history.id}>
                                      <td>{history.shift_day}</td>
                                      <td>{history.prev_value}</td>
                                      <td>{history.new_value}</td>
                                      <td>
                                        {dateFormatter(
                                          history.created_at,
                                          "MMM dd, yyyy hh:mm bb"
                                        )}
                                      </td>
                                      <td>{history.created_by}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div className="drew-pagination">
                              {currentData.length > 0
                                ? "Showing " +
                                  (startIndex + 1) +
                                  " to " +
                                  (endIndex > currentData.length
                                    ? currentData.length
                                    : endIndex) +
                                  " of " +
                                  currentData.length
                                : ""}

                              <ul class="pagination ml-2">
                                {pages.map((number) => (
                                  <li
                                    key={number}
                                    id={number}
                                    className={
                                      this.state.historyCurrentPage == number
                                        ? "paginate_button page-item active"
                                        : "paginate_button page-item"
                                    }
                                    onClick={() =>
                                      this.setState({
                                        historyCurrentPage: number,
                                      })
                                    }
                                  >
                                    <a class="page-link">{number}</a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div> */}
                          <div
                            class="tab-pane fade show"
                            id="earning-deduction"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-two-home-tab"
                          >
                            <div className="row mb-10">
                              <div className="col-md-12">
                                {this.state.permissions > 2 ? (
                                  <button
                                    className="btn btn-sm btn-info float-right"
                                    onClick={this.handleRefresh}
                                    disabled={this.state.refreshDisabled}
                                  >
                                    <Refresh />
                                    &nbsp;Refresh Table
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div class="row mb-10">
                              <div class="col-md-6"></div>
                              <div class="col-md-6">
                                <input
                                  type="search"
                                  class="form-control form-control-sm col-md-6 col-sm-6 float-right"
                                  placeholder="Search"
                                  onChange={this.handleSearchED}
                                />
                              </div>
                            </div>
                            <div class="table-responsive mb-10">
                              <table class="table table-sm table-bordered table-striped mb-0">
                                <thead className="thead-secondary">
                                  <tr className="text-center">
                                    <th
                                      onClick={() =>
                                        this.handleSortED(
                                          "name",
                                          this.state.nameSort
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Earning/Deduction &nbsp;
                                      <i
                                        className={
                                          this.state.nameSort == "ASC"
                                            ? "fa fa-arrow-down"
                                            : "fa fa-arrow-up"
                                        }
                                      ></i>
                                    </th>
                                    <th
                                      onClick={() =>
                                        this.handleSortED(
                                          "type",
                                          this.state.typeSort
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Transaction Type &nbsp;
                                      <i
                                        className={
                                          this.state.typeSort == "ASC"
                                            ? "fa fa-arrow-down"
                                            : "fa fa-arrow-up"
                                        }
                                      ></i>
                                    </th>
                                    <th
                                      onClick={() =>
                                        this.handleSortED(
                                          "pay_cycle_name",
                                          this.state.pay_cycle_nameSort
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Pay Period Release &nbsp;
                                      <i
                                        className={
                                          this.state.pay_cycle_nameSort == "ASC"
                                            ? "fa fa-arrow-down"
                                            : "fa fa-arrow-up"
                                        }
                                      ></i>
                                    </th>
                                    <th>Amount</th>
                                    <th>Last Update / Updated By</th>
                                    <th style={{ width: "90px" }}>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.edFilteredData.map((ed) => (
                                    <tr key={ed.id}>
                                      <td>{ed.name}</td>
                                      <td className="text-center">
                                        {ed.is_add == 1 ? (
                                          <strong className="text-success">
                                            Earning
                                          </strong>
                                        ) : (
                                          ""
                                        )}
                                        {ed.is_statutory == 1 ? (
                                          <strong className="text-warning">
                                            Statutory
                                          </strong>
                                        ) : (
                                          ""
                                        )}
                                        {ed.is_deduction == 1 ? (
                                          <strong className="text-danger">
                                            Deduction
                                          </strong>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {ed.pay_cycle_name}
                                      </td>
                                      <td className="text-right">
                                        {ed.amount_f}
                                      </td>
                                      <td className="text-center">
                                        {dateFormatter(
                                          ed.updated_at,
                                          "MMM dd, yyyy hh:mm aa"
                                        )}
                                        <br />
                                        {ed.updated_by}
                                      </td>
                                      <td className="text-center">
                                        {this.state.permissions > 2 ? (
                                          <EditEdDetail
                                            parentCallback={
                                              this.handleEditShiftCallback
                                            }
                                            payCycle={
                                              this.state.empDetails
                                                .company_pay_cycle
                                            }
                                            edDetail={ed}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade show active"
                            id="salary"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-two-home-tab"
                          >
                            <div className="row mb-10">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <h5>Salary Details</h5>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 float-right">
                                {this.state.permissions > 2 ? (
                                  <EditEmpSalary
                                    empDetail={this.state.empDetails}
                                    schemaDD={this.state.schemaDD}
                                    bschemaDD={this.state.bschemaDD}
                                    parentCallback={
                                      this.handleEditShiftCallback
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="row mb-10">
                              <div className="col-xl-3 col-sm-10">
                                <strong>Monthly Basic</strong>
                              </div>
                              <div className="col-xl-3 col-sm-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.empDetails.basic_rate_f}
                              </div>
                              <div className="col-xl-3 col-sm-10">
                                <strong>Hourly Rate</strong>
                              </div>
                              <div className="col-xl-3 col-sm-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.empDetails.hour_rate_f}
                              </div>
                            </div>
                            <div className="row mb-10">
                              <div className="col-xl-3 col-sm-10">
                                <strong>With Regular Holiday Pay</strong>
                              </div>
                              <div className="col-xl-3 col-sm-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.empDetails.reg_holiday_pay_f}
                              </div>
                              <div className="col-xl-3 col-sm-10">
                                <strong>With Special Holiday Pay</strong>
                              </div>
                              <div className="col-xl-3 col-sm-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.empDetails.spl_holiday_pay_f}
                              </div>
                            </div>

                            <div className="row mb-10">
                              <div className="col-xl-3 col-sm-10">
                                <strong>Tardiness On?</strong>
                              </div>
                              <div className="col-xl-3 col-sm-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.empDetails.tardiness_on_f}
                              </div>
                              <div className="col-xl-3 col-sm-10">
                                <strong>Undertime On?</strong>
                              </div>
                              <div className="col-xl-3 col-sm-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.empDetails.undertime_on_f}
                              </div>
                            </div>
                            {/* <div className="row mb-10">
                              <div className="col-xl-3 col-sm-10">
                                <strong>Required Working Hours</strong>
                              </div>
                              <div className="col-xl-7 col-sm-10">
                                :&nbsp;&nbsp;&nbsp;
                                {
                                  this.state.empDetails.required_working_hours
                                }{" "}
                                {this.state.empDetails.required_working_hours
                                  ? "Hours"
                                  : ""}
                              </div>
                            </div> */}
                            <div className="row mb-10">
                              <div className="col-xl-3 col-sm-10">
                                <strong>Basic Pay Schema</strong>
                              </div>
                              <div className="col-xl-7 col-sm-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.empDetails.bschema_name}
                              </div>
                            </div>
                            <div className="row mb-10">
                              <div className="col-xl-3 col-sm-10">
                                <strong>Overtime Pay Schema</strong>
                              </div>
                              <div className="col-xl-7 col-sm-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.empDetails.schema_name}
                              </div>
                            </div>

                            <div className="row mb-10">
                              <div className="col-xl-3 col-sm-10">
                                <strong>Tax Bracket</strong>
                              </div>
                              <div className="col-xl-7 col-sm-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.taxBracket.rate
                                  ? this.state.taxBracket.rate == 0
                                    ? "0% (NONE)"
                                    : this.state.taxBracket.additional == 0
                                    ? this.state.taxBracket.rate * 100 +
                                      "% of the excess over ₱ " +
                                      (
                                        this.state.taxBracket.tax_lower_range +
                                        ""
                                      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : "₱ " +
                                      (
                                        this.state.taxBracket.additional + ""
                                      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                      " + " +
                                      this.state.taxBracket.rate * 100 +
                                      "% of the excess over ₱ " +
                                      (
                                        this.state.taxBracket.tax_lower_range +
                                        ""
                                      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : ""}
                              </div>
                            </div>
                            <br />
                            <div className="row mb-10">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <h5>Salary History</h5>
                              </div>
                            </div>
                            <div class="table-responsive mb-10">
                              <table class="table table-sm table-bordered table-striped mb-0">
                                <thead className="thead-secondary">
                                  <tr className="text-center">
                                    <th>
                                      Previous
                                      <br />
                                      Basic
                                    </th>
                                    <th>
                                      New
                                      <br />
                                      Basic
                                    </th>
                                    <th>
                                      Previous
                                      <br />
                                      Hour Rate
                                    </th>
                                    <th>
                                      New
                                      <br />
                                      Hour Rate
                                    </th>
                                    <th>Date Edited</th>
                                    <th>Changed By</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.salaryData.map((ed) => (
                                    <tr key={ed.id}>
                                      <td className="text-right">
                                        {ed.prev_rate_f}
                                      </td>
                                      <td className="text-right">
                                        {ed.new_rate_f}
                                      </td>
                                      <td className="text-right">
                                        {ed.prev_hour_f}
                                      </td>
                                      <td className="text-right">
                                        {ed.new_hour_f}
                                      </td>
                                      <td>
                                        {dateFormatter(
                                          ed.updated_at,
                                          "MMM dd, yyyy hh:mm bb"
                                        )}
                                      </td>
                                      <td>{ed.updated_by}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <h1 className="m-0 text-center">
                  Your credentials is not sufficient to access this:
                  <br />
                </h1>
                <br />
                <h2 className="text-center">
                  Module : Master Data
                  <br />
                  Access Type : {access[this.state.permissions]}
                  <br />
                  Please contact your Payroll Administrator.
                  <br />
                  <a className="btn btn-primary btn-sm" href="/#/app">
                    Back to Dashboard
                  </a>
                </h2>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ViewEmployee);
