import React, { Component, useEffect, useState } from "react";
import Header from "../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { FindInPage } from "@material-ui/icons";
import { dateFormatter } from "../../components/DtFormatter";
import TimesheetStagingRerun from "./TimesheetStagingRerun";
import TimesheetStgPost from "./TimesheetStgPost";
const access = ["For Review", "View Only", "Add", "Add / Edit", "Full Access"];
const status = ["FOR REVIEW", "POSTED"];
const cookies = new Cookies();

export default function TimesheetStaging() {
  const permissions = cookies.get("permissions")["timesheet"];
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    let company_id = 0;
    if (cookies.get("transCompany")) company_id = cookies.get("transCompany");

    if (company_id == 0) {
      cookies.set("load", "1");
      alert("Please Select Module Company");
      window.location.replace("/#/app");
    } else {
      const fetchData = async () => {
        let apiLink = "api/stg_attendance/" + cookies.get("transCompany");
        const res = await axios.get(apiLink);
        setData(res.data);
        setFilteredData(res.data);
      };

      if (!fileLoaded) {
        fetchData();
        setFileLoaded(true);
      }
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !data["description"]
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row mb-10">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3>Review Timesheets</h3>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="btn-group float-right"></div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mt-10">
                  <div class="table-wrap">
                    <div class="row mb-10">
                      <div class="col-md-6">
                        <label>
                          <select
                            class="custom-select custom-select-sm form-control form-control-sm"
                            onChange={handleRecordPerPage}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>{" "}
                        items
                      </div>
                      <div class="col-md-6">
                        <input
                          type="search"
                          class="form-control form-control-sm col-md-6 col-sm-6 float-right"
                          placeholder="Search"
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div class="table-responsive mb-10">
                      <table class="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center text-nowrap">
                            <th>Timesheet Description</th>
                            <th>Employee Count</th>
                            <th>Status</th>
                            <th>Created On</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length > 0 ? (
                            currentData.map((row) => (
                              <tr key={row.id}>
                                <td>{row.description}</td>
                                <td className="text-right">
                                  {row.employee_count}
                                </td>
                                <td className="text-center">
                                  <strong
                                    className={
                                      row.status == "FOR REVIEW"
                                        ? "text-danger"
                                        : "text-success"
                                    }
                                  >
                                    {status[row.status]}
                                  </strong>
                                </td>
                                <td className="text-center">
                                  {dateFormatter(
                                    row.created_at,
                                    "MMM dd, yyyy hh:mm bb"
                                  )}
                                </td>
                                <td>
                                  <div className="btn-group">
                                    {permissions > 2 ? (
                                      row.status == 0 ? (
                                        <TimesheetStagingRerun
                                          id={row.id}
                                          name={row.description}
                                          parentCallback={handleAddCallback}
                                        />
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {permissions > 2 ? (
                                      row.status == 0 ? (
                                        <TimesheetStgPost
                                          id={row.id}
                                          name={row.description}
                                          parentCallback={handleAddCallback}
                                        />
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    <Link
                                      to={{
                                        pathname: `/timesheet_stg/peremp/${row.id}`,
                                        query: `/timesheet_stg`,
                                      }}
                                      className="btn btn-sm btn-outline-primary"
                                      title={"View Timesheet Details"}
                                    >
                                      <FindInPage />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <ul class="pagination float-right">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Timesheets
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
